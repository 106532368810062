<div class="content">
    <h1>Portfolio</h1>
    <div class="portfolio-cards">
      <app-card cardTitle="Portfolio">
        The one you're on now!
      </app-card>
      <app-card cardTitle="Social Media Website">
          A barebones social media website, most akin to Facebook.
      </app-card>
      <app-card cardTitle="Social Media Website v2">
        A rewritten version of Social Media Website, written in newer technologies.
      </app-card>
    </div>
</div>
