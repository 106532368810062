<div class="nav-content">
    <div class="line-thing"></div>
    <ul class="button-links">
        <li><button [ngClass]="{'selected-route': activeRoute == '/'}" (click)="navigate('/')">Home</button></li>
        <li><button [ngClass]="{'selected-route': activeRoute == '/portfolio'}"(click)="navigate('portfolio')">Portfolio</button></li>
        <li><button [ngClass]="{'selected-route': activeRoute == '/about'}"(click)="navigate('about')">About</button></li>
        <li><button [ngClass]="{'selected-route': activeRoute == '/contact'}"(click)="navigate('contact')">Contact</button></li>
    </ul>
    <ul class="icons">
        <li><a href="https://github.com/latenitekid" target="_blank"><i class="fab fa-github"></i></a></li>
        <li><a href="https://www.linkedin.com/in/nicholas-miller-0180bb6b/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
    </ul>
    <div class="line-thing"></div>
</div>
